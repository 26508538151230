import React from 'react';

import classes from './Border.module.css';

const Border = props => {
    if (props.side === 'bottom') {
        return (
            <React.Fragment>
                <div className={classes.Border2} />
                <div className={classes.Border1} />
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className={classes.Border1} />
                <div className={classes.Border2} />
            </React.Fragment>
        );
    }
}

export default Border;