import React, { useEffect, useState } from 'react';

import classes from './Slide.module.css';

import Border from '../components/UI/Border/Border';
import { Container, Row, Col, Button } from 'react-bootstrap';

import finishImage from '../images/finish_image.png';

import Modal from '../components/UI/Modal/Modal';
import { sendEmail } from '../request';

const FinishSlide = props => {

    const [emailSent, setEmailSent] = useState(false);

    const marks = Object.entries(props.scoreCard.marks).map(([questionId, mark]) => mark);
    const score = marks.filter(mark => mark).length;

    const questions = marks.length;
    const scorePercent = score / questions;
    const hasPassed = scorePercent >= 0.8;

    useEffect(() => {
        if (!emailSent) {

            const data = {
                ...props.scoreCard
            };
            sendEmail(data);

            setEmailSent(true);
        }
    }, [emailSent]);

    return (
        <React.Fragment>
            <Modal show={true} centered className={classes.FinishModal} >
                <h3>{props.scoreCard.firstName} {props.scoreCard.lastName}</h3>
                <Container >
                    <Row>
                        <Col><p>{props.scoreCard.email}</p></Col>
                    </Row>
                    <Row>
                        <Col>Your Score</Col>
                        <Col>{scorePercent * 100}% ({score} pts)</Col>
                    </Row>
                    <Row>
                        <Col>Passing Score</Col>
                        <Col>80%</Col>
                    </Row>
                    <Row>
                        <h3>Result: {hasPassed ? 'Pass' : 'Fail'}</h3>
                    </Row>
                </Container>
            </Modal>
            <div className={[classes.Slide, classes.FinishSlide, classes.BlueTheme].join(' ')}>
                <Border />
                <img alt="Intro" className={classes.HeroImage} src={finishImage} />
                <div className={classes.Footer}>
                    <Border side="bottom" />
                    <Container>
                        <Row>
                            <Col xs={12} sm={{ span: 10, order: 0 }} className={classes.Title}>
                                {hasPassed
                                    ? <h1>Congratulations you've scored at least 80% and completed the module. Please close this webpage.</h1>
                                    : <h1>Unfortunately you scored less than 80% and have not completed the module. Please click the Retry button to return to the module.</h1>}
                            </Col>
                            <Col xs={12} sm={{ span: 2, order: 1 }} className={classes.Next}>
                                {!hasPassed
                                    ? <Button onClick={props.retry}>Retry</Button>
                                    : null}
                            </Col>
                        </Row>
                    </Container>

                </div>
            </div >
        </React.Fragment>
    );
}

export default FinishSlide;