import React from 'react';
import { Button } from 'react-bootstrap';

const Controls = props => {
    return (
        <React.Fragment>
            {props.hasExit ? <Button onClick={props.exitHandler}>Exit</Button> : null}
            {props.hasPrev ? <Button onClick={props.prevHandler}>Back</Button> : null}
            {props.hasNext ? <Button onClick={props.nextHandler} disabled={!props.nextEnabled}>Next</Button> : null}
        </React.Fragment>
    );
}

export default Controls;