import React from 'react';

import './App.css';

import Slides from './containers/Slides';

function App() {
  return (
    <div className="App">
      <div className="SlideContainer" >
        <Slides />
      </div>
    </div>
  );
}

export default App;