import React from 'react';
import Sound from 'react-sound';

import classes from './Slide.module.css';
import Border from '../components/UI/Border/Border';
import { Container, Row, Col, Form } from 'react-bootstrap';
import ContactData from './ContactData';
import Controls from '../components/UI/Slide/Controls';
import FinishSlide from './FinishSlide';

const slideGenerator = (slideDef, contactFormUpdatedHandler, contactFormValidHandler,
    nextButtonEnabled, nextHandler, prevHandler, exitHandler, retryHandler,
    contactDetails, answers, answerSelectedHandler, marks) => {

    const controlsForSlideDef = slideDef => {
        return (
            <Controls
                hasNext={Boolean(slideDef.nextId)} nextHandler={nextHandler} nextEnabled={nextButtonEnabled}
                hasPrev={Boolean(slideDef.prevId)} prevHandler={prevHandler}
                hasExit={Boolean(slideDef.hasExitButton)} exitHandler={exitHandler}
            />
        );
    }

    const classArray = [classes.Slide];
    if (slideDef.theme === 'blue') {
        classArray.push(classes.BlueTheme)
    }

    if (slideDef.name) {
        classArray.push(classes["Slide-" + slideDef.name]);
    }

    let audioElement = null;
    if (slideDef.audio) {
        audioElement = <Sound
            url={slideDef.audio}
            playStatus={Sound.status.PLAYING}
            autoLoad={true}
            onLoading={() => { console.log('Audio loading: ', slideDef.audio); }}
            onLoad={() => { console.log('Audio loaded'); }}
            onPlaying={() => { console.log('Audio playing'); }}
            onFinishedPlaying={() => { console.log('Audio finished'); }}

        />
    }

    switch (slideDef.type) {
        case 'intro':
            return (<div className={[classes.Slide, classes.HeroSlide, classes.BlueTheme].join(' ')}>
                <Border />
                <img alt="Intro" className={classes.HeroImage} src={slideDef.image} />
                <div className={classes.Footer}>
                    <Border side="bottom" />
                    <Container>
                        <Row>
                            <Col xs={12} sm={{ span: 4, order: 1 }} className={classes.Title}>
                                <h1>{slideDef.title}</h1>
                            </Col>
                            <Col xs={12} sm={{ span: 6, order: 0 }}>
                                <ContactData contactFormUpdated={contactFormUpdatedHandler} contactFormValid={contactFormValidHandler} />
                            </Col>
                            <Col xs={12} sm={{ span: 1, order: 2 }} className={classes.Next}>
                                {controlsForSlideDef(slideDef)}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>);

        case 'copy-then-image-columns':
            return (
                <div className={classArray.join(' ')}>
                    <div className={classes.Header}>
                        <Border />
                    </div>
                    <Container fluid className={classes.SlideContent}>
                        <Row>
                            <Col xs={12} sm={{ span: 4, order: 1 }} className={classes.SlideImageCol}>
                                <img alt="Slide" className={[classes.SlideImage].join(' ')} src={slideDef.image} />
                            </Col>
                            <Col xs={12} sm={{ span: 8, order: 0 }} className={classes.SlideCopy}>
                                {slideDef.copy}
                            </Col>
                        </Row>
                    </Container>
                    <div className={classes.Footer}>
                        <Border side="bottom" />
                        {controlsForSlideDef(slideDef)}
                    </div>
                    {audioElement}
                </div >
            );

        case 'wide-image-then-copy-rows':
            classArray.push(classes.WideImageThenCopyRowSlide)
            return (
                <div className={classArray.join(' ')}>
                    <div className={classes.Header}>
                        <Border />
                    </div>
                    <Container fluid className={classes.SlideContent}>
                        <Row>
                            <Col xs={12} className={[classes.SlideImageCol, classes.SlideWideImageCol].join(' ')}>
                                <img alt="Slide" className={[classes.SlideWideImage].join(' ')} src={slideDef.image} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className={classes.SlideCopy}>
                                {slideDef.copy}
                            </Col>
                        </Row>
                    </Container>
                    <div className={classes.Footer}>
                        <Border side="bottom" />
                        {controlsForSlideDef(slideDef)}
                    </div>
                    {audioElement}
                </div >
            );

        case 'copy-radio-then-image-columns':
            return (
                <div className={classArray.join(' ')}>
                    <div className={classes.Header}>
                        <Border />
                    </div>
                    <Container fluid className={classes.SlideContent}>
                        <Row>
                            <Col xs={12} sm={{ span: 4, order: 1 }} className={classes.SlideImageCol}>
                                <img alt="Slide" className={[classes.SlideImage].join(' ')} src={slideDef.image} />
                            </Col>
                            <Col xs={12} sm={{ span: 8, order: 0 }} className={classes.SlideCopy}>
                                {slideDef.copy}
                                {slideDef.choices.map(([id, label]) => (
                                    <Form.Check
                                        key={'answer' + id}
                                        name="q21"
                                        type='radio'
                                        checked={answers[slideDef.questionId] === id}
                                        id={id}
                                        label={label}
                                        onChange={e => answerSelectedHandler(id, e.currentTarget.checked)} />))}
                            </Col>
                        </Row>
                    </Container>
                    <div className={classes.Footer}>
                        <Border side="bottom" />
                        {controlsForSlideDef(slideDef)}
                    </div>
                    {audioElement}
                </div >
            );


        case 'finish':
            console.log('Marks', marks);
            const scoreCard = {
                ...contactDetails,
                answers: {
                    ...answers
                },
                marks: {
                    ...marks
                }
            };
            console.log('scorecardddd', scoreCard);
            return (
                <FinishSlide scoreCard={scoreCard} retry={retryHandler} />
            );

        default:
            return <h1>Unrecognised slide type: {slideDef.type}</h1>;
    }
}

export default slideGenerator;