import { useState } from 'react';

import SlideManager from './SlideManager';
import slideGenerator from './SlideGenerator';

const slideManager = new SlideManager(1);

const Slides = props => {

    const [currentSlide, setCurrentSlide] = useState(1);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

    const [contactDetails, setContactDetails] = useState({
        firstName: 'Joe',
        lastName: 'Bloggs',
        companyName: 'B & Q',
        email: 'test@example.com'
    });

    const [answers, setAnswers] = useState({
        '1': 'B',
        '2': ''
    });

    const [marks, setMarks] = useState({
        '1': true,
        '2': false
    });

    const slideDef = slideManager.getSlideDef(currentSlide);

    const contactFormUpdatedHandler = contactForm => {
        console.log(contactForm);
        setContactDetails(contactForm);
    }

    const contactFormValidHandler = isValid => {
        console.log(isValid);
        setNextButtonEnabled(isValid);
    }

    const nextButtonHandler = () => {
        const slideDef = slideManager.getSlideDef(currentSlide);
        if (slideDef.nextId) {
            setCurrentSlide(slideDef.nextId);
        }
    }

    const prevButtonHandler = () => {
        const slideDef = slideManager.getSlideDef(currentSlide);
        if (slideDef.prevId) {
            setCurrentSlide(slideDef.prevId);
        }
    }

    const exitButtonHandler = () => {
        setCurrentSlide(1);
    }

    const retryHandler = () => {
        setCurrentSlide(slideManager.getQuestionsStartSlideId());
    }

    const answerSelectedHandler = (answerId, isChecked) => {
        console.log('Answer', answerId, 'isChecked', isChecked);

        const newAnswers = {
            ...answers,
            [slideDef.questionId]: answerId
        };
        setAnswers(newAnswers);

        const correct = slideDef.correctAnswer === answerId;
        const newMarks = {
            ...marks,
            [slideDef.questionId]: correct
        };
        setMarks(newMarks);
    }

    const slide = slideGenerator(slideDef, contactFormUpdatedHandler, contactFormValidHandler,
        nextButtonEnabled, nextButtonHandler, prevButtonHandler, exitButtonHandler, retryHandler,
        contactDetails, answers, answerSelectedHandler, marks);

    return slide;

}

export default Slides