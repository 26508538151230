import React, { useState } from "react";
// import { connect } from "react-redux";
// import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler';

import classes from './ContactData.module.css';
import { updateObject, checkValidity } from '../hoc/shared/utility';

import Input from '../components/UI/Input/Input';
import { Container, Row, Col } from "react-bootstrap";

const formConfig = {
    firstName: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'First name'
        },
        row: 0,
        validation: {
            required: true
        }
    },
    lastName: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Last name'
        },
        row: 0,
        validation: {
            required: true
        }
    },
    companyName: {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Company name'
        },
        row: 1,
        validation: {
            required: false
        }
    },
    email: {
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: 'Email'
        },
        row: 2,
        validation: {
            required: true,
            isEmail: true
        },
    }
};


const ContactData = props => {
    const [contactForm, setContactForm] = useState({
        firstName: {
            value: '',
            valid: false,
            touched: false
        },
        lastName: {
            value: '',
            valid: false,
            touched: false
        },
        companyName: {
            value: '',
            valid: true,
            touched: false
        },
        email: {
            value: '',
            valid: false,
            touched: false
        }
    });

    const inputChangedHandler = (event, inputIdentifier) => {
        const updatedFormElement = updateObject(contactForm[inputIdentifier], {
            value: event.target.value,
            valid: checkValidity(event.target.value, formConfig[inputIdentifier].validation),
            touched: true
        });

        const updatedOrderForm = updateObject(contactForm, {
            [inputIdentifier]: updatedFormElement
        });

        let formIsValid = true;
        for (let inputIdentifier in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid
        }

        setContactForm(updatedOrderForm);

        const formData = {}
        for (let formElementIdentifier in updatedOrderForm) {
            formData[formElementIdentifier] = updatedOrderForm[formElementIdentifier].value;
        };
        props.contactFormUpdated(formData);
        props.contactFormValid(formIsValid);
    }

    const formElements = new Map();
    for (let key in contactForm) {
        const row = formConfig[key].row;
        let collection = formElements.get(row);
        if (!collection) {
            collection = [];
            formElements.set(row, collection);
        }

        collection.push({
            id: key,
            config: formConfig[key],
            value: contactForm[key].value,
            valid: contactForm[key].valid,
            touched: contactForm[key].touched
        });
    }

    let form = (
        <form>
            <Container className={classes.Container}>
                {Array.from(formElements).map(([row, rowElements]) => (
                    <Row noGutters={true}>
                        {rowElements.map(formElement => (
                            <Col xs={12 / rowElements.length}>
                                <Input
                                    key={formElement.id}
                                    elementType={formElement.config.elementType}
                                    elementConfig={formElement.config.elementConfig}
                                    shouldValidate={formElement.config.validation}
                                    value={formElement.value}
                                    invalid={!formElement.valid}
                                    touched={formElement.touched}
                                    changed={(event) => inputChangedHandler(event, formElement.id)}
                                />
                            </Col>
                        ))}
                    </Row>
                ))}
            </Container>
        </form>
    );

    return (
        <div className={classes.ContactData}>
            {form}
        </div>
    );
}

// const mapStateToProps = state => {
//     return {
//         ingredients: state.burgerBuilder.ingredients,
//         price: state.burgerBuilder.totalPrice,
//         loading: state.order.loading,
//         token: state.auth.token,
//         userId: state.auth.userId
//     };
// }

// const mapDispatchToProps = dispatch => {
//     return {
//         onOrderBurger: (orderData, token) => dispatch(actions.purchaseBurger(orderData, token))
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(ContactData, axios));
export default ContactData;