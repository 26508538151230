import React from 'react';

import classes from './Slide.module.css';

import Border from '../components/UI/Border/Border';

import slide01Feature from '../images/slide01_feature.png';
import slide02Image from '../images/slide02_image.png';
import slide03Image from '../images/slide03_image.png';
import slide04Image from '../images/slide04_image.png';
import slide20Image from '../images/slide20_image.png';
import slide21Image from '../images/slide21_image.png';
import finishImage from '../images/finish_image.png';

import slide02Audio from '../audio/2.mp3';
import slide03Audio from '../audio/3.mp3';
import slide04Audio from '../audio/4.mp3';
import slide20Audio from '../audio/20.mp3';
import q01Audio from '../audio/q01.mp3';
import q02Audio from '../audio/q02.mp3';

import ContactData from './ContactData';
import { Container, Row, Col } from 'react-bootstrap';
import FinishSlide from './FinishSlide';
import scoreCard from '../components/UI/Slide/ScoreCard';
import Controls from '../components/UI/Slide/Controls';

const slide22Image = slide02Image;

class SlideManager {
    nextId = 1;
    slides = [];
    notifySlideChanged;
    questionsStartSlideId;

    constructor(notifySlideChanged) {

        this.notifySlideChanged = notifySlideChanged;

        this.addSlide({
            type: 'intro',
            name: 'Intro',
            title: 'Contractor Induction',
            image: slide01Feature
        }, false);

        this.addSlide({
            type: 'copy-then-image-columns',
            name: 'Slide02',
            image: slide02Image,
            audio: slide02Audio,
            copy: this.createSlide02Copy(),
            hasExitButton: true
        }, true);

        this.addSlide({
            type: 'copy-then-image-columns',
            name: 'Slide03',
            image: slide03Image,
            audio: slide03Audio,
            copy: this.createSlide03Copy(),
            hasExitButton: true
        }, true);

        this.addSlide({
            type: 'wide-image-then-copy-rows',
            name: 'Slide04',
            image: slide04Image,
            audio: slide04Audio,
            copy: this.createSlide04Copy(),
            hasExitButton: true
        }, true);

        const questionSection = this.addSlide({
            type: 'wide-image-then-copy-rows',
            name: 'QuestionsIntro',
            theme: 'blue',
            image: slide20Image,
            audio: slide20Audio,
            copy: this.createSlide20Copy(),
            hasExitButton: true
        }, true);
        this.questionsStartSlideId = questionSection.id;

        this.addSlide({
            type: 'copy-radio-then-image-columns',
            theme: 'blue',
            name: 'Question01',
            image: slide21Image,
            audio: q01Audio,
            copy: this.createSlide21Copy(),
            hasExitButton: true,
            questionId: 1,
            choices: [
                ['A', 'Work as quickly as possible, to get the job done'],
                ['B', 'Plan, manage, conduct and supervise your work to comply with legislation and best practice'],
                ['C', 'Use whatever plant, equipment and are to hand to complete the task'],
                ['D', 'Start work as soon as you arrive']
            ],
            correctAnswer: 'B'
        }, true);

        this.addSlide({
            type: 'copy-radio-then-image-columns',
            name: 'Question02',
            theme: 'blue',
            image: slide22Image,
            audio: q02Audio,
            copy: this.createSlide22Copy(),
            hasExitButton: true,
            questionId: 2,
            choices: [
                ['A', 'Be aware of others'],
                ['B', 'Obey any displayed site speed limits'],
                ['C', 'Park only in designated areas or where instructed'],
                ['D', 'All of the above']
            ],
            correctAnswer: 'D'
        }, true);

        this.addSlide({
            type: 'finish',
            name: 'Finish',
            image: finishImage,
            hasExitButton: true,
        }, true);
    }

    retryQuestions = () => {
        console.log('Restarting questions. Using slide id', this.questionsStartSlideId);
        this.notifySlideChanged(this.questionsStartSlideId);
    }

    getQuestionsStartSlideId = () => this.questionsStartSlideId;

    addSlide = (slideDef, linkToPrevSlide) => {
        const newSlideId = this.nextId;
        this.nextId++;

        if (linkToPrevSlide) {
            const lastSlideDef = this.getLastSlideDef();
            lastSlideDef.nextId = newSlideId;
            slideDef.prevId = lastSlideDef.id;
        }

        slideDef.id = newSlideId;
        this.slides.push(slideDef);
        return slideDef;
    };

    getSlideDef = slideId => {
        const [slideDef] = this.slides.filter(slideDef => slideDef.id === slideId)
        return slideDef;
    }

    getLastSlideDef = () => {
        return this.slides[this.slides.length - 1];
    };

    createSlide02Copy = () => {
        return (
            <React.Fragment>
                <h1>Pfizer Limited, Discovery Park Sandwich</h1>
                <h1>Contractor Induction</h1>
                <h2>Welcome to Pfizer Limited at Discovery Park, Sandwich.</h2>
                <p>This induction provides you with informaton on the current hazards of the site
                and tells you about essential site rules to keep you safe and avoid incident
                    whilst here. </p>
                <p className={classes.Attention}>Please pay attention for the next few minutes.</p>
            </React.Fragment>
        );
    };

    createSlide03Copy = () => {
        return (
            <React.Fragment>
                <h1>Environmental, Health and Safety (EHS) Excellence</h1>
                <p>We believe EHS excellence enhances our business and social performance through the
                minimisation of losses and liabilities. We are committed to protecting the
                environment, and the health and safety of everyone working at our site. We want to
                ensure you have a clear understanding of your EHS responsibilities so that you
                stay safe and avoid incident whilst here. We expect you to <span className={classes.Attention}>plan, manage conduct</span> and <span className={classes.Attention}>supervise</span> your work activities in compliance
                    with legislation and best practice. </p>
            </React.Fragment>
        );
    };

    createSlide04Copy = () => {
        return (
            <React.Fragment>
                <h1>Pfizer Builidings</h1>
                <Container fluid>
                    <Row className='noGutters'>
                        <Col xs={12} sm={3}>
                            <p>Pfizer is a tenant here at Discovery Park.</p>
                        </Col>
                        <Col xs={12} sm={3}>
                            <p>We are located in the three buildings highlighted.</p>
                        </Col>
                        <Col xs={12} sm={3}>
                            <p>Be aware of others whilst here.</p>
                        </Col>
                        <Col xs={12} sm={3}>
                            <p>Obey any site speed limits and parking restrictions.</p>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    };

    createSlide20Copy = () => {
        return (
            <React.Fragment>
                <h1>You must pass this knowledge check to obtain your Pfizer Contractor
                    Pass and gain access to the Pfizer areas here at Discovery Park.</h1>
                <p>Select the correct answers that apply. You will need to score 80% to complete the module.</p>
                <p>You will have 3 attempts at passwing. If you are unsuccessful, site access will iniitially
                    be denied and you should contact your Pfizer Responsible Person/contact.</p>
                <p className={classes.Instruction}>Please click the next button below to continue.</p>
            </React.Fragment>
        );
    };

    createSlide21Copy = () => {
        return (
            <React.Fragment>
                <h1>QUESTION</h1>
                <h1>Whilst you are working here for Pfizer, we expect you to:</h1>
                <p className={classes.Instruction}>Select which answer you think best matches the question</p>
            </React.Fragment>
        );
    };

    createSlide22Copy = () => {
        return (
            <React.Fragment>
                <h1>QUESTION</h1>
                <h1>Pfizer is a tenant at Discovery Park along with many other companies. Whilst you are here it important to:</h1>
                <p className={classes.Instruction}>Select which answer you think best matches the question</p>
            </React.Fragment>
        );
    };
}

export default SlideManager;
